import Capsule, { useCapsule } from 'react-capsule'
import _ from 'lodash'

import { LocalStorage } from './LocalStorage'

class StoredCapsule extends Capsule {
  constructor (initialState, storeKey) {
    super(initialState)
    this.storeKey = storeKey
    this.restoreAsync()
  }

  isObject (obj) {
    return obj !== null && typeof obj === 'object'
  }

  async restoreAsync () {
    try {
      const storedState = await LocalStorage.get(this.storeKey)
      storedState !== null && super.setState(storedState)
    } catch (e) { }
  }

  patchState (newState) {
    if (!this.isObject(newState) || !this.isObject(this.state)) return false
    this.setState({ ...this.state, ...newState })
  }

  setState (newState) {
    super.setState(newState)
    LocalStorage.set(this.storeKey, newState)
    /* __DEV__ &&
      // eslint-disable-next-line no-console
      console.log(
        'SetStore',
        this.storeKey,
        this.isObject(newState) ? _.omit(newState, 'accessToken') : newState
      ) */
  }

  reset () {
    super.reset()
    LocalStorage.delete(this.storeKey)
  }

  subscribe (func) {
    super.subscribe(func)
  }

  unsubscribe (func) {
    super.unsubscribe(func)
  }
}

export { StoredCapsule, useCapsule, Capsule }
