import AsyncStorage from '@react-native-async-storage/async-storage'

export const LocalStorage = {
  get: async (key) => {
    try {
      const value = await AsyncStorage.getItem(key)
      if (value !== null) return JSON.parse(value)
    } catch (error) { }
    return {}
  },

  set: async (key, value) => {
    try {
      await AsyncStorage.setItem(key, JSON.stringify(value))
      return true
    } catch (error) { }
    return false
  },

  delete: async (key) => {
    try {
      await AsyncStorage.removeItem(key)
      return true
    } catch (error) { }
    return false
  },

  merge: async (key, value) => {
    try {
      await AsyncStorage.mergeItem(key, JSON.stringify(value))
      return true
    } catch (error) { }
    return false
  },

  clear: async () => {
    try {
      await AsyncStorage.clear()
    } catch (error) { }
  }
}
