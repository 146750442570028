import { useEffect, useState } from 'react'
import { useCapsule } from 'react-capsule'
import Home from './Pages/Home/Home'
import Login from './Pages/Login/Login'
import { clientService, FC } from './Services/Client'
import { cap } from './State/Capsules'
import { QueryClientProvider } from 'react-query'
import useMount from 'react-use/lib/useMount'
import DownloadPage from './Pages/Download/Download'

function App () {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [token, setToken] = useState('')

  const [Authorization, setAuthorization] = useCapsule(cap.Authorization)

  const checkLogin = async () => {
    if (Authorization) {
      try {
        const res = await FC.service('authentication').create({
          strategy: 'jwt',
          accessToken: Authorization
        })
        if (res.accessToken) {
          setIsLoggedIn(true)
        }
      } catch (e) {
        console.log('ERR => ', e)
      }
    }
  }

  useMount(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')
    setToken(token)
  })

  useEffect(() => {
    checkLogin()
  }, [Authorization])

  return token
    ? <DownloadPage token={token} />
    : isLoggedIn
      ? <QueryClientProvider client={clientService}>
        <Home />
        </QueryClientProvider>
      : <Login token={token} />
}

export default App
