import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import Flex from '../../Components/Common/Flex'
import { clientService, FC } from '../../Services/Client'
import { useCapsule } from 'react-capsule'
import { cap } from '../../State/Capsules'

const Form = ({ edit, setEdit }) => {
  const [name, setName] = useState('')
  const [society, setSociety] = useState('')
  const [pIva, setPIva] = useState('')
  const [status, setStatus] = useState('')
  const [file, setFile] = useState('')
  const [email, setEmail] = useState('')
  const [PEC, setPEC] = useState('')

  const [Authorization] = useCapsule(cap.Authorization)

  // Stati richiesto default
  // Quando file esiste pronto invio e ci deve essere bottone per invio mail (sia pec che mail)
  // quando è stato scaricato dare stato scaricato
  // utilizzare nodecron per controllare ogni minuto se c'è un file all'interno della cartella e verifica se si chiama fsocietà
  // inviare poi mail con link pubblico e codice per scaricare il file

  useEffect(() => {
    if (Object.keys(edit).length) {
      setName(edit.name || '')
      setSociety(edit.society || '')
      setStatus(edit.status || '')
      setFile(edit.file || '')
      setEmail(edit.email || '')
      setPEC(edit.pec || '')
      setPIva(edit.pIva || '')
    }
  }, [edit])

  const addClient = async () => {
    try {
      await FC.service('clients').create({
        name,
        society,
        pIva,
        status: 1,
        file,
        email,
        pec: PEC
      }, {
        headers: {
          Authorization
        }
      })
      clientService.invalidateQueries('clients')
      setName('')
      setSociety('')
      setStatus('')
      setFile('')
      setPIva('')
      setEmail('')
      setPEC('')
    } catch (err) {
      console.log(err)
    }
  }

  const EditClient = async () => {
    try {
      await FC.service('clients').patch(
        edit._id,
        {
          name,
          society,
          pIva,
          file,
          email,
          pec: PEC
        },
        {
          headers: {
            Authorization
          }
        })
      clientService.invalidateQueries('clients')
      setName('')
      setSociety('')
      setStatus('')
      setFile('')
      setEmail('')
      setPEC('')
      setPIva('')
      setEdit(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Flex style={{ margin: '0px 20px', backgroundColor: 'lightgray', textAlign: 'center', alignItems: 'center', justifyContent: 'center', borderRadius: 10, padding: '10px 20px' }}>
      <h3>Inserisci nuovo cliente</h3>
      <InputText value={name} onChange={(e) => setName(e.target.value)} style={{ margin: '10px -10px' }} placeholder='Nome' />
      <InputText value={society} onChange={(e) => setSociety(e.target.value)} style={{ margin: '10px -10px' }} placeholder='Codice Società' />
      {/* <InputText value={status} onChange={(e) => setStatus(e.target.value)} style={{ margin: '10px -10px' }} placeholder='Stato' /> */}
      {/* <InputText value={file} onChange={(e) => setFile(e.target.value)} style={{ margin: '10px -10px' }} placeholder='File' /> */}
      {/* <InputText value={email} onChange={(e) => setEmail(e.target.value)} style={{ margin: '10px -10px' }} placeholder='Email' /> */}
      <InputText value={PEC} onChange={(e) => setPEC(e.target.value)} style={{ margin: '10px -10px' }} placeholder='PEC' />
      <InputText value={pIva} onChange={(e) => setPIva(e.target.value)} style={{ margin: '10px -10px' }} placeholder='Partita Iva' />
      <Button label={edit ? 'Modifica' : 'Aggiungi cliente'} style={{ margin: '10px -10px' }} onClick={edit ? EditClient : addClient} disabled={!name && !society && !status && !file && !email && !PEC && !pIva} />
      {edit && <Button
        label='Esci da modalità di modifica' style={{ margin: '10px -10px' }} onClick={() => {
          setEdit(false)
          setName('')
          setSociety('')
          setPIva('')
          setStatus('')
          setFile('')
          setEmail('')
          setPEC('')
        }}
               />}
    </Flex>
  )
}

export default Form
