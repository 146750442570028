import { StoredCapsule, Capsule } from './StoredCapsule'

const capsules = {

}

const storedCapsules = {
  loggedIn: false,
  user: {},
  Authorization: ''
}

const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, fn(k, v)]))

const cap = {
  ...objectMap(storedCapsules, (key, value) => new StoredCapsule(value, key)),
  ...objectMap(capsules, (key, value) => new Capsule(value))
}

/* const navigate = (page) => cap.page.setState(page) */

export { cap }
