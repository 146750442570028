import React, { useRef, useState } from 'react'
import Flex from '../../Components/Common/Flex'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { FC } from '../../Services/Client'
import { useCapsule } from '../../State/StoredCapsule'
import { cap } from '../../State/Capsules'

const Login = () => {
  const toast = useRef(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [Authorization, setAuthorization] = useCapsule(cap.Authorization)

  const login = async () => {
    try {
      const res = await FC.service('authentication').create({
        strategy: 'local',
        email: email,
        password: password
      })
      if (res.accessToken) {
        toast.current.show({ severity: 'success', summary: '', detail: 'Login avvenuto con successo', life: 3000 })
        setAuthorization(res.accessToken)
      }
    } catch {
      toast.current.show({ severity: 'error', summary: 'Errore Login', detail: 'Credenziali errate', life: 3000 })
    }
  }

  return (
    <Flex>
      <Toast ref={toast} />
      <h1>LOGIN</h1>
      <span className='p-float-label' style={{ margin: 20 }}>
        <InputText id='in' value={email} onChange={(e) => setEmail(e.target.value)} />
        <label htmlFor='in'>Email</label>
      </span>
      <span className='p-float-label'>
        <InputText id='in' value={password} onChange={(e) => setPassword(e.target.value)} type='password' />
        <label htmlFor='in'>Password</label>
      </span>
      <Button style={{ margin: 20 }} label='Login' onClick={login} />
    </Flex>
  )
}

export default Login
