import React, { useState } from 'react'
import Flex from '../../Components/Common/Flex'
/* import useMount from 'react-use/lib/useMount'
import { FC } from '../../Services/Client' */
import { InputText } from 'primereact/inputtext'
import { Text } from '../../Components/Common/Text'
import { Button } from 'primereact/button'
import { FC } from '../../Services/Client'
import { Spinner } from '../../Components/Common/Spinner'

const DownloadPage = ({ token }) => {
  const [password, setPassword] = useState('')
  const [passwordErr, setPasswordErr] = useState(false)
  const [loading, setLoading] = useState(false)
  const checkClientPassword = async () => {
    try {
      setLoading(true)
      const client = await FC.service('clients').get(password)
      if (client.websiteToken === token) {
        setPasswordErr(false)
        // DEV SETTINGS window.location.href = `http://localhost:3030/files/${client.file}`
        window.location.href = window._env_ && window._env_.API_BASE_URL ? `${window._env_.API_BASE_URL}/files/${client.file}` : `http://localhost:3030/files/${client.file}`
        setPassword('')
      } else {
        setPasswordErr(true)
      }
    } catch (e) {
      console.log(e)
      setPasswordErr(true)
    }
    setLoading(false)
  }

  return (
    <Flex>
      <Flex style={{ padding: 20, backgroundColor: 'rgba(240,240,240)', width: '40%', borderRadius: 20, marginTop: 40 }}>
        <h2>Download</h2>
        <Text value='Inserisci la password fornita nella mail per scaricare il file' />
        <InputText placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} style={{ marginTop: 20, marginBottom: 10 }} />
        {passwordErr && <Text value='La password inserita non è corretta' color='red' />}
        {!loading
          ? <Button
              style={{ marginTop: 10 }}
              label='Scarica' onClick={checkClientPassword}
            />
          : <Spinner />}
      </Flex>
    </Flex>
  )
}

export default DownloadPage
